.externalContainer {
  max-width: 960px !important;
  margin: 0 auto !important;
}

@media (min-width: 1200px) {
  .externalContainer {
    max-width: 1500px !important;
    margin: 0 auto !important;
  }
}

@media (min-width: 992px) {
  .externalContainer {
    max-width: 1200px !important;
    margin: 0 auto !important;
  }
}

.externalContainerMax600 {
  max-width: 600px !important;
  margin: 0 auto !important;
}

.labelCategory {
  width: 100%;
  color: #bfbbbb;
  font-size: 10px;
  margin: 0;
}

.itemCardItem {
  margin: 5px !important;
}

.textCard {
  padding: 0 10px;
  height: 60px;
}

.headerTitle {
  display: flex;
  margin-top: 20px;
  margin-bottom: 16px;
  width: 100%;
  align-items: center;
}

.rightHeaderTitle {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem !important;
  margin-bottom: 0.7rem;
  list-style: none;
  border-radius: 7px;
  color: #7987a1;
  font-size: 10px !important;
  width: 180px;
}

.mainTitleCategory {
  color: #323251;
  font-weight: 500;
  font-size: 15px;
  text-indent: -1px;
  line-height: 18px;
  position: relative;
}

.textCard {
  padding: 0 10px;
  height: 60px;
}

.maxContent {
  word-break: break-word;
  width: max-content;
  max-width: 350px;
}

.tagSize {
  width: 50px;
}

.headerContentFull {
  padding: 1.2rem;
}

.mediaBody {
  margin-left: 0 !important;
  flex: 1 1;
}

.imgCategory {
  width: 150px !important;
}

.basicRoundedCircle {
  width: 35px !important;
  height: 35px !important;
  font-size: 15px !important;
  line-height: 35px;
  text-align: center;
}

.headerContent {
  padding: 0 1.2rem 1.2rem;
}

.scrollableList {
  min-height: 400px; /* Adjust the height as needed */
  overflow: auto; /* Enable scrolling */
}

.scrollableContent {
  border-bottom: 1px solid #ddd;
}

.carouselCard {
  width: 100%; /* Sets the width of the card relative to its container */
  max-width: 800px; /* Adjust this value based on your design needs */
  margin: 0 auto; /* Centers the card in the carousel item */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Optional: Adds a shadow for better visibility */
  border-radius: 10px; /* Optional: Rounds the corners of the card */
  overflow: hidden; /* Optional: Ensures the content doesn't overflow the card */
}


