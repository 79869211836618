/* Wishlist.module.css */

.toogleBtn {
  margin: 0 1rem;
}

.iconToggleBtn {
  display: inline-flex;
}

/* BEGIN: PRONTO HEADER */

.prontoHeader {
  background-color: transparent;
  z-index: 100;
  height: 64px;
  background: #fff;
  border-bottom: 1px solid #ededf5;
  z-index: 1025 !important;
  width: 100% !important;
  box-shadow: 0px 7px 26px rgba(154, 154, 204, 0.1);
  top: 0;
}

.prontoHeader > .container,
.prontoHeader > .container-fluid {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
}

.prontoHeader .dropdown-menu {
  border: 1px solid #ededf5 !important;
}

.prontoHeader .dropdown-menu .main-message-list .dropdown-item {
  padding: 0.75rem;
}

.prontoHeader .dropdown-menu .main-message-list .dropdown-item:last-of-type {
  border-bottom: 0 !important;
}

.prontoHeader .dropdown-menu .main-notification-list a:last-of-type {
  border-bottom: 0 !important;
}

@media (min-width: 992px) {
  .prontoHeader > .container,
  .prontoHeader > .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 992px) {
  .prontoHeader > .container {
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .prontoHeader > .container {
    max-width: none;
  }
}

/* END: PRONTO HEADER */
