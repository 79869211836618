.floating-btn {
  position: fixed; /* Fixed position */
  bottom: 30px; /* 30px from the bottom */
  right: 30px; /* 30px from the right */
  z-index: 999; /* Ensure it's above other items */
  border-radius: 50% !important; /* Rounded shape */
  width: 60px; /* Large size */
  height: 60px; /* Large size */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
}

/* Adjust the icon size if needed */
.floating-btn .bi {
  font-size: 1.5rem; /* Larger icon */
}
